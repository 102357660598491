$(document).ready(function () {
    var e = $("#video_background"),
        t = $("#image_background");
    e.on("ended", function () {
        $(this).addClass("d-none"),
            t.removeClass("d-none"),
            t.addClass("d-block");
    });
    var n = document.getElementById("jsAcceptCookies");
    n &&
        n.addEventListener("click", function () {
            localStorage.setItem("acceptCookies", "true"),
                o.classList.add("d-none");
        });
    var o = document.getElementById("jsAcceptCookiesMain");
    function s(e) {
        var t = $("body").scrollTop() + $("body").height();
        return $(e).offset().top + $(e).height() <= t;
    }
    function i() {
        $(".counter-digit").each(function () {
            var e = $(this),
                t = e.attr("data-count");
            "true" != e.attr("ended") &&
                s(e) &&
                ($({ countNum: e.text() }).animate(
                    { countNum: t },
                    {
                        duration: 2500,
                        easing: "swing",
                        step: function () {
                            e.text(Math.floor(this.countNum));
                        },
                        complete: function () {
                            e.text(this.countNum);
                        },
                    }
                ),
                e.attr("ended", "true"));
        });
    }
    o &&
        "true" != localStorage.getItem("acceptCookies") &&
        o.classList.remove("d-none"),
        s(".counter-digit") && i(),
        $("body").scroll(function () {
            s(".counter-digit") && i();
        }),
        (jQuery.event.special.touchstart = {
            setup: function (e, t, n) {
                this.addEventListener("touchstart", n, {
                    passive: !t.includes("noPreventDefault"),
                });
            },
        }),
        (jQuery.event.special.touchmove = {
            setup: function (e, t, n) {
                this.addEventListener("touchmove", n, {
                    passive: !t.includes("noPreventDefault"),
                });
            },
        }),
        (jQuery.event.special.wheel = {
            setup: function (e, t, n) {
                this.addEventListener("wheel", n, { passive: !0 });
            },
        }),
        (jQuery.event.special.mousewheel = {
            setup: function (e, t, n) {
                this.addEventListener("mousewheel", n, { passive: !0 });
            },
        });
});
